<template>
    <div class="mt-lg-3 mt-1 pt-3 pb-0 px-3">
        <b-card class="card-material" style="max-width: 60rem;">
            <h4>{{ $t('help.support') }}</h4>
            <p class="card-text" v-html="getDescription()"/>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "Help",

        setup(props, {root}) {
            function getDescription() {
                let email = root.$OEM === 'pleion' ? 'support@pleion.it' : 'support@things.al';

                return `${root.$t('help.supportDesc1')} <a href="mailto:${email}" target="_top">${email}</a><br /> ${root.$t('help.supportDesc2')}`
            }

            return {
                getDescription
            }
        }
    }
</script>

<style scoped>

</style>